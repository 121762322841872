import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Gallery from '../components/Gallery'

import dev01 from '../assets/images/development/lonehill-X41.jpg'
import dev02 from '../assets/images/development/germiston.jpeg'

const CD_IMAGES = [
    { id: '1', src: dev01, thumbnail: dev01, caption: 'Lonehill X41', description: 'It\'s situated in the safest suburb of Johannesburg. The property size including roads is 1.95 Hectares (Ha). It is zoned for residential, maximum 3 storeys. It has a density of 70 units/Ha with coverage of 30% and floor area ratio of 0.8. Ideal for walk-up apartments (85-100sqm 2-3bedrooms/unit) or freestanding clusters (150-200sqm 3bedrooms/unit).'},
    { id: '2', src: dev02, thumbnail: dev02, caption: 'Germiston', description: 'It\'s surrounded by industrial areas, merely 1 min drive from the N17 highway, and 5 min walking distance to the Reef Shopping Centre. The site is 8000sqm, zoned for 84 walk-up residential apartments. Phase 1 (18 units) all sold and transferred. Remaining units 66. Average unit size 62sqm (2 bedrooms and 1 bath).'}
];
const siteTitle = "Property Developments in South Africa | The Fu Foundation"
const siteDescription = "Current and prospective development opportunities in South Africa."


const Property = (props) => (
    <Layout>
        <Helmet>
            <title>{siteTitle}</title>
            <meta name="description" content={siteDescription} />
        </Helmet>

        <div id="main" className="alt">
            <section id="property-developments-current">
                <div className="inner">
                    <header className="major">
                        <h1>Current Developments</h1>
                    </header>

                    <Gallery images={CD_IMAGES.map(({ id, src, thumbnail, caption, description }) => ({
                        src,
                        thumbnail,
                        caption,
                        description
                    }))} />

                </div>
            </section>

            <section id="property-developments-prospective">
                <div className="inner">
                    <header className="major">
                        <h1>Prospective Developments</h1>
                    </header>
                    <div>
                        <h3>Lonehill X53</h3>
                        <p>It's situated in the safest suburb of Johannesburg, nearby Lonehill X41. The property size is 3.2Ha (including roads). It is zoned for residential, maximum 3 storeys. It has density of 50 units/Ha with coverage of 50% and floor area ratio of 0.8. Ideal for freestanding 3-storeys clusters (150-200+sqm 3bedrooms/unit).</p>
                    </div>
                    <div>
                        <h3>Rabie Ridge/Midrand</h3>
                        <p>The site is 129Ha. Mixed rights have been applied for: retail (15Ha), office, residential (from 120 units/Ha), clinics and school.</p>
                    </div>
                    <div>
                        <h3>Bredell AH/Kempton Park</h3>
                        <p>The site has its own dam, less than 2km from the Highway offramp (Pomona). It is 29Ha. Mixed rights to be applied for: retail/office/industrial, residential (from 80 units/Ha).</p>
                    </div>
                    <div>
                        <h3>Poortview/Roodepoort</h3>
                        <p>The site is situated in a region of nature and tranquility. Less than 750m from Walter Sisulu National Botanical Garden. The property is 2Ha. Ideal for guest houses/accommodations.</p>
                    </div>
                    <div>
                        <h3>Vaal Dam/Free State</h3>
                        <p>The property is 816Ha with 9km waterfront. Ideal for mixed zoning rights: retail/business, residential, clinics and schools.</p>
                    </div>
                </div>
            </section>

{/* DEVNOTE: refactor this later, remove duplicate code */}
            <section id="contact-us">
                <h2>Get In Touch</h2>
                <p>Should you require additional information regarding our developments, rental stocks, or the exporting of minerals, please feel free to send us an email. Alternatively, complete the form below and we will get back to you.</p>
                <div className="row">
                    <div className="8u 12u$(small)">
                        <form name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
                            <input type="hidden" name="bot-field" />
                            <div className="row uniform 50%">
                                <div className="6u 12u$(xsmall)"><input type="text" name="name" id="name" placeholder="Name" /></div>
                                <div className="6u 12u$(xsmall)"><input type="email" name="email" id="email" placeholder="Email" /></div>
                                <div className="12u"><textarea name="message" id="message" placeholder="Message" rows="4"></textarea></div>
                            </div>
                            <br/>
                            <ul className="actions">
                                <li><input type="submit" value="Send Message" /></li>
                                <li><input type="reset" value="Clear" /></li>
                            </ul>
                        </form>
                    </div>
                    <div className="4u 12u$(small)">
                        <ul className="labeled-icons">
                            <li>
                                <h3 className="icon fa-home"><span className="label">Address</span></h3>
                                13 Chester Rd.<br />
                                Parkwood, Johannesburg<br />
                                South Africa
                            </li>
                            <li>
                                <h3 className="icon fa-envelope-o"><span className="label">Email</span></h3>
                                <a href="mailto:info@thefufoundation.com" target="_top">info@thefufoundation.com</a><br/>
                                <a href="mailto:rental@thefufoundation.com" target="_top">rental@thefufoundation.com</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>       
        </div>

    </Layout>
)

export default Property